export function getCookie(name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}

export function deleteCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}

export function setCookie(name, value, { path = '/', maxAge, expires, secure, sameSite } = {}) {
  let cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`

  if (maxAge) cookie += `; max-age=${maxAge}`
  if (expires) cookie += `; expires=${expires.toUTCString()}`
  cookie += `; path=${path}`
  if (secure) cookie += `; secure`
  if (sameSite) cookie += `; samesite=${sameSite}`

  document.cookie = cookie
}
