import { IHttp, IHttpResponse } from 'services/common/Http'
import axios from 'axios'
import { getCookie } from '~/utils/getCookie'

export interface IMindboxMethods {
  sendMindboxEvent(operation: string, data: any): any
}

export default class Mindbox implements IMindboxMethods {
  constructor(http: IHttp) {}
  mindboxBaseApi = 'https://api.mindbox.ru/v3/operations/'

  sendMindboxEvent(operation: string, data: any): any {
    if (process.env.NODE_ENV === 'production') {
      // @ts-ignore
      return window.mindbox('sync', {
        operation: `Website.${operation}`,
        data,
        onSuccess: this.mindboxReqSuccess,
        onError: this.mindboxReqError,
      })
    }
  }

  mindboxReqSuccess() {
    console.log('event success')
  }
  mindboxReqError(err: any) {
    console.log(err)
  }

  async getMindboxOrderBonus(products: any) {
    const UUID = getCookie('mindboxDeviceUUID')
    const environment = process.env.MINDBOX_SOURCE
    const lines = products.map((product: any) => {
      return {
        "minPricePerItem": product.prices.standard.price,
        "costPricePerItem": product.prices.standard.price,
        "basePricePerItem": product.prices.standard.price,
        "quantity": product.count || '1',
        "product": {
          "ids": {
            "website": product.id
          }
        },
        "status": {
          "ids": {
            "externalId": "2"
          }
        }
      }
    })
    return await axios.post(
      `${this.mindboxBaseApi}sync?endpointId=${environment}&operation=Website.CalculateUnauthorizedCart&deviceUUID=${UUID}`,
      {
        "order": {
          lines: lines
        }
      },
      {
        headers: {
          Authorization: process.env.MINDBOX_AUTH_KEY
        }
      }
    )
  }

  async getMindboxUserPoints(id: string) {
    const UUID = getCookie('mindboxDeviceUUID')
    const environment = process.env.MINDBOX_SOURCE
    return await axios.post(
      `${this.mindboxBaseApi}sync?endpointId=${environment}&operation=Website.CheckCustomerActive&deviceUUID=${UUID}`,
      {
        "customer": {
          "ids": {
            "mindboxId": +id
          }
        }
      },
      {
        headers: {
          Authorization: process.env.MINDBOX_AUTH_KEY
        }
      }
    )
  }
}
