import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IHttpParams } from 'services/common/Http'
import { IOrder } from 'types/DTO/order'
import { IGeocoderAddress } from 'types/DTO/geocoder'

export const state = () => ({
  orderWasSended: false,
  orderNumber: '',
  user: {
    avatar: '',
    cart: '',
    'cloud-cards': [],
    defaultaddress: '',
    email: '',
    id: '',
    name: '',
    phone: '',
    'player-id': '',
  },
  orderGuest: false,
  isRecipient: true,
  address: {
    id: '',
    street: '',
    house: '',
    flat: '',
    entrance: '',
    floor: '',
    private_home: '',
    city_id: '',
    longitude: '',
    latitude: '',
    comment: '',
    name: '',
    phone: '',
    formatted_address: '',
    uri: '',
  },
  newAddress: {} as IGeocoderAddress,
  city_id: 1,
  anotherRecipientName: '',
  anotherRecipientPhone: '',

  products: [],
  productsPrice: [],
  promocode: '',

  paymentOptions: [],
  deliveryOptions: [],
  deliveryPrice: 0,
  cityOptions: [],

  deliveryType: {
    id: '',
    postIndex: '',
  },
  paymentType: {
    id: '',
  },
  comment: '',
  total: 0,

  card_cloud: '',
  newCardPay: false,
  isNewAddress: false,
  deliveryTime: [],
  indexWindow: '',
  order_card_cloud: {},
  order: {},
  orderInitResponse: {},
  isOrderValid: false,
  isOrderContactValid: true,
  isOrderPaymentValid: false,
  isOrderAddressValid: false,
  isOrderDeliveryValid: false,
  isOrderScheduleValid: false,
  isRecipesValid: true,
  isOrderError: false,
  isCardSaved: false,
  recipeFiles: [] as any,
  promocodeDiscount: 0,
  isFreeDelivery: false,
  isFixedPrice: false,
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setOrderInitResponse(state, payload) {
    state.orderInitResponse = payload
  },
  clearOrderInitResponse(state) {
    state.orderInitResponse = {}
  },
  setOrderProducts(state, payload) {
    state.products = payload
  },
  setOrderProductsPrice(state, payload) {
    state.productsPrice = payload
  },
  setOrderAddress(state, payload) {
    state.address = payload
  },
  setCity(state, payload) {
    state.city_id = payload
  },
  clearOrderAddress(state) {
    state.address = {
      id: '',
      street: '',
      house: '',
      flat: '',
      entrance: '',
      floor: '',
      private_home: '',
      city_id: '',
      longitude: '',
      latitude: '',
      comment: '',
      name: '',
      phone: '',
      formatted_address: '',
      uri: '',
    }
  },
  setNewAddress(state, payload) {
    state.newAddress = payload
  },
  setOrderAnotherRecipientName(state, payload) {
    state.anotherRecipientName = payload
  },
  setOrderAnotherRecipientPhone(state, payload) {
    state.anotherRecipientPhone = payload
  },
  setOrderUser(state, payload) {
    state.user = payload
  },
  setOrderDeliveryOptions(state, payload) {
    state.deliveryOptions = payload
  },
  setDeliveryPrice(state, payload) {
    state.deliveryPrice = payload
    const deliveryOption: any = state.deliveryOptions.find((option: any) => option.id === 23)
    if (deliveryOption) {
      deliveryOption.price = payload
    }
  },
  setFreeDelivery(state, payload) {
    state.isFreeDelivery = payload
  },
  setFixedDelivery(state, payload) {
    state.isFixedPrice = payload
  },
  setOrderMethod(state, payload) {
    state.deliveryType = payload
  },
  setOrderPromocode(state, payload) {
    state.promocode = payload
  },
  setOrderPaymentOptions(state, payload) {
    state.paymentOptions = payload
  },
  setOrderComment(state, payload) {
    state.comment = payload
  },
  setPaymentMethod(state, payload) {
    state.paymentType = payload
  },
  setOrderTotal(state, payload) {
    state.total = payload
  },
  setOrder(state, payload) {
    state.order = payload
  },
  setCardCloud(state, payload) {
    state.card_cloud = payload
  },
  setNewCardPay(state, payload) {
    state.newCardPay = payload
  },
  setIsOrderValid(state, payload) {
    state.isOrderValid = payload
  },
  setIsOrderScheduleValid(state, payload) {
    state.isOrderScheduleValid = payload
  },
  setIsOrderContactValid(state, payload) {
    state.isOrderContactValid = payload
  },
  setIsOrderPaymentValid(state, payload) {
    state.isOrderPaymentValid = payload
  },
  setIsOrderAddressValid(state, payload) {
    state.isOrderAddressValid = payload
  },
  setIsOrderDeliveryValid(state, payload) {
    state.isOrderDeliveryValid = payload
  },
  setOrderCities(state, payload) {
    state.cityOptions = payload
  },
  setIsNewAddress(state, payload) {
    state.isNewAddress = payload
  },
  setIsRecipient(state, payload) {
    state.isRecipient = payload
  },
  setOrderCardCloud(state, payload) {
    state.order_card_cloud = payload
  },
  setIsCardSaved(state, payload) {
    state.isCardSaved = payload
  },
  clearOrderDeliveryType(state) {
    state.deliveryType = {
      id: '',
      postIndex: '',
    }
  },
  setIsRecipesValid(state, payload) {
    state.isRecipesValid = payload
  },
  addRecipe(state, payload) {
    state.recipeFiles.push(payload)
  },
  removeRecipe(state, payload) {
    const index = state.recipeFiles.map((recipe: any) => recipe.id).indexOf(payload)
    state.recipeFiles.splice(index, 1)
  },
  clearOrderFields(state) {
    state.orderWasSended = false
    state.orderNumber = ''
    state.user = {
      avatar: '',
      cart: ',',
      'cloud-cards': [],
      defaultaddress: '',
      email: '',
      id: '',
      name: '',
      phone: '',
      'player-id': '',
    }
    state.orderGuest = false
    state.isRecipient = true
    state.address = {
      id: '',
      street: '',
      house: '',
      flat: '',
      entrance: '',
      floor: '',
      private_home: '',
      city_id: '',
      longitude: '',
      latitude: '',
      comment: '',
      name: '',
      phone: '',
      formatted_address: '',
      uri: '',
    }
    state.city_id = 1
    state.newAddress = {} as IGeocoderAddress
    state.anotherRecipientPhone = ''
    state.anotherRecipientName = ''

    state.promocode = ''

    state.paymentOptions = []
    state.deliveryOptions = []
    state.cityOptions = []
    state.deliveryType = {
      id: '',
      postIndex: '',
    }
    state.paymentType = {
      id: '',
    }
    state.total = 0
    state.comment = ''
    state.card_cloud = ''
    state.newCardPay = false
    state.isNewAddress = false
    state.deliveryTime = []
    state.indexWindow = ''
    state.order_card_cloud = {}
    state.isOrderValid = false
    state.isOrderContactValid = true
    state.isOrderPaymentValid = false
    state.isOrderAddressValid = false
    state.isOrderScheduleValid = false
    state.isOrderError = false
    state.recipeFiles = []
  },
  clearOrder(state) {
    state.order = {}
  },

  setPromocodeDiscount(state, payload) {
    state.promocodeDiscount = payload
  },
}

export const actions: ActionTree<stateT, stateT> = {
  async orderInit({ commit }, data: any) {
    try {
      const params = new URLSearchParams()
      params.append('type', 'delivery_windows')
      const res = await this.$repositories.order.orderInit(data, { params })
      commit('setOrderPaymentOptions', res.data.payment_method)
      commit('setOrderCities', res.data.city)
      if (res.data.promocode !== undefined && res.data.promocode.code !== undefined) {
        commit('setOrderPromocode', res.data.promocode)
      }
      commit('setOrderInitResponse', res.data)
    } catch (e) {
      console.log('Order init error:', e)
    }
  },
  async addOrder({ commit }, data: IOrder) {
    try {
      const res = await this.$repositories.order.addOrder(data, {})
      return res.data
    } catch (e) {
      console.log('add order error:', e)
    }
  },
  async getOrderPaymentMethods({ commit }, params: IHttpParams) {
    try {
      const res = await this.$repositories.order.findOrderPaymentMethods(params)
      commit('setOrderPaymentOptions', res.data.data)
      console.log('payment options fetched')
      // @ts-ignore
    } catch {
      console.log('error: getOrderPaymentMethods')
    }
  },
  async getOrderInfoById({ commit }, { id, params }) {
    try {
      const res = await this.$repositories.order.findOrderById(id, params)
      commit('setOrder', res.data.data[0].attributes)
    } catch (e) {
      console.log('getOrderInfoById', e)
    }
  },
  async updateOrderStatus({ commit }, { id, params }) {
    try {
      return await this.$repositories.order.updateOrderStatus(id, params)
    } catch (e: any) {
      console.log('updateOrderStatus', e)
      return e.response
    }
  },
  async getDeliveryArea({ commit }, params) {
    try {
      const res = await this.$repositories.order.findDeliveryArea(params)
      commit('setOrderDeliveryOptions', res.data.data)
    } catch (e) {
      console.log('error: getDelievryArea')
    }
  },

  async getDeliveryPrice({ commit }, params) {
    commit('setDeliveryPrice', 0)
    try {
      const res = await this.$repositories.order.getDeliveryPrice(params)
      commit('setDeliveryPrice', Math.round(res.data.price))
    } catch (e) {
      this.$services.toaster.add({
        type: 'error',
        title: '',
        description: 'Не удалось определить цену доставки, попробуйте позже',
      })
      commit('setDeliveryPrice', 0)
      console.log('error: getDeliveryPrice')
    }
  },
}

export const getters: GetterTree<stateT, stateT> = {
  orderInitResponse(state) {
    return state.orderInitResponse
  },

  orderAttributes(state) {
    return {
      street: state.address.street,
      house: state.address.house,
      flat: state.address.private_home ? '' : state.address.flat,
      ...(!state.address.private_home &&
        state.address.entrance && { entrance: state.address.entrance }),
      ...(!state.address.private_home && state.address.floor && { floor: state.address.floor }),
      private_home: state.address.private_home ? 1 : 0,
      city_id: state.address.city_id || 1,
      formatted_address: state.address.formatted_address,
      uri: state.address.uri,
      comment: state.comment,
      ...(!state.isRecipient && { name: state.anotherRecipientName }),
      ...(!state.isRecipient && { phone: state.anotherRecipientPhone }),
      delivery_type: state.deliveryType.id,
      zipcode: state.deliveryType.postIndex,
      payment_type: state.paymentType.id,
      card_cloud: state.card_cloud,
      products_id: state.products,
      promocode: state.promocode,
      total: state.total,
      user_id: state.user.id,
      window_date_id: state.indexWindow,
    }
  },

  orderDeliveryAttributes(state) {
    return {
      street: state.address.street,
      house: state.address.house,
      city_id: state.address.city_id || 1,
      flat: state.address.flat,
      formatted_address: state.address.formatted_address,
      uri: state.address.uri,
      ...(!state.address.private_home &&
        state.address.entrance && { entrance: state.address.entrance }),
      ...(!state.address.private_home && state.address.floor && { floor: state.address.floor }),
      longitude: state.address.longitude,
      latitude: state.address.latitude,
    }
  },

  deliveryPrice(state) {
    return state.deliveryPrice
  },

  currentOrderAddress(state) {
    return state.address
  },

  orderDeliveryOptions(state) {
    return state.deliveryOptions
  },
  orderPaymentOptions(state) {
    return state.paymentOptions
  },
  orderProducts(state) {
    return state.products
  },
  orderPromocode(state) {
    return state.promocode
  },
  orderProductsPrice(state) {
    return state.productsPrice
  },
  order(state) {
    return state.order
  },
  orderChosenPaymentType(state) {
    return state.paymentType
  },
  orderNewCardPay(state) {
    return state.newCardPay
  },
  orderNewAddress(state) {
    return state.newAddress
  },
  isOrderError(state) {
    return state.isOrderError
  },
  isOrderValid(state) {
    const isValid = state.isOrderContactValid && !!state.deliveryType.id
    // recipes func
    // state.isRecipesValid
    if (Number(state.deliveryType.id) !== 8 && Number(state.deliveryType.id) !== 10) {
      return isValid && state.isOrderAddressValid
    }
    return isValid
  },
  isOrderPaymentValid(state) {
    return state.isOrderPaymentValid
  },
  orderCardCloud(state) {
    return state.card_cloud
  },
  isOrderContactValid(state) {
    return state.isOrderContactValid
  },
  isOrderAddressValid(state) {
    return state.isOrderAddressValid
  },
  isOrderScheduleValid(state) {
    return state.isOrderScheduleValid
  },
  isOrderDeliveryValid(state) {
    return state.isOrderDeliveryValid
  },
  isRecipesValid(state) {
    return state.isRecipesValid
  },
  orderCities(state) {
    return state.cityOptions
  },
  selectedAddressId(state) {
    return state.address.id
  },
  selectedDeliveryType(state) {
    return state.deliveryType
  },
  isNewAddress(state) {
    return state.isNewAddress
  },
  selectedCity(state) {
    return state.city_id
  },
  orderComment(state) {
    return state.comment
  },
  orderCardCloudData(state) {
    return state.order_card_cloud
  },
  newCardPay(state) {
    return state.newCardPay
  },
  isCardSaved(state) {
    return state.isCardSaved
  },

  promoCodeDiscount(state) {
    return state.promocodeDiscount
  },
  recipeFiles(state) {
    return state.recipeFiles
  },
  anotherRecipientPhone(state) {
    return state.anotherRecipientPhone
  },
  anotherRecipientName(state) {
    return state.anotherRecipientName
  },
  isFreeDelivery(state) {
    return state.isFreeDelivery
  },
  isFixedPrice(state) {
    return state.isFreeDelivery
  },
}
