import { GetterTree, MutationTree, ActionTree } from 'vuex'

export const state = () => ({
  data: [] as string[],
  transaction_id: null,
  currentDate: new Date(),
  abrOfDays: ['Сегодня', 'Завтра', 'Послезавтра'],
  months: [
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря',
  ],
  daysOfWeek: [
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
    'Воскресенье',
  ],
  work_time: ['11:00 - 12:00', '14:00 - 15:00', '20:00 - 21:00'],
  work_time_today: [
    new Date().getHours() + 2 < 11 ? '11:00 - 12:00' : '',
    new Date().getHours() + 2 < 14 ? '14:00 - 15:00' : '',
    new Date().getHours() + 2 < 20 ? '20:00 - 21:00' : '',
  ],
  checkoutError: '',
  isPointsUsed: false
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const actions: ActionTree<stateT, stateT> = {
  getScheduleData({ commit, state }) {
    const data: string[] = []

    for (let i = 0; i < 3; i++) {
      const res = `
      ${state.abrOfDays[i]},
      ${new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * i).getDate()}
      ${state.months[state.currentDate.getMonth()]}
      ${state.currentDate.getFullYear()}`

      data.push(res)
    }

    commit('setDate', data)
  },

  async sendUseBonusStatus({ commit }, data) {
    await this.$repositories.order.sendUseBonusStatus(data)
  }
}

export const mutations: MutationTree<stateT> = {
  setDate(state, payload) {
    state.data = payload
  },
  setCheckoutError(state, payload) {
    state.checkoutError = payload
  },

  setTransactionId(state,payload) {
    state.transaction_id=payload
  },

  setIsPointsUsed(state,payload) {
    state.isPointsUsed = payload
  }
}

export const getters: GetterTree<stateT, stateT> = {
  data(state) {
    return state.data
  },
  getWorkTime(state) {
    return state.work_time
  },
  getWorkTimeToday(state) {
    return state.work_time_today
  },
  checkoutError(state) {
    return state.checkoutError
  },

  transactionId(state) {
    return state.transaction_id
  },

  isPointsUsed(state) {
    return state.isPointsUsed
  }
}
